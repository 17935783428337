import { IconButton, styled, TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import TableRow from '@mui/material/TableRow';
import { useStore } from '../../../hooks/useStore';
import Typography from '@mui/material/Typography';
import { COLOR_BLACK, COLOR_ERROR, COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import { FileAndDocumentKey } from '../DragAndDropFileInput';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import React, { useState } from 'react';

const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  width: 17px;
  height: 17px;
  padding: 4px;
  background-color: ${COLOR_ERROR};
`;

interface FileNotSupportedCardProps {
  file: FileAndDocumentKey;
  progress: number;
  removeFileByKey: (key: string) => void;
}

const FileNotSupportedCard = ({ file, progress, removeFileByKey }: FileNotSupportedCardProps) => {
  const {
    localizationStore: { i18next: i18n },
    appState: { s3DocumentsApi },
  } = useStore();

  const [loading, setLoading] = useState(false);

  const handleRemoveFile = async () => {
    if (!file.documentKey) {
      return;
    }

    try {
      setLoading(true);
      await s3DocumentsApi.deleteFile(file.documentKey);
      removeFileByKey(file.documentKey);
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TableRow sx={{ height: '40px' }}>
      <StyledTableCell colSpan={2}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconContainer>
            <ErrorOutlineOutlinedIcon sx={{ fill: COLOR_WHITE, fontSize: '14px !important' }} />
          </IconContainer>
          <Typography sx={{ color: COLOR_ERROR, marginLeft: '6px' }}>
            {i18n.t<string>('knowledgeBase.files.notSupported', { fileName: file.file.name })}
          </Typography>
        </Box>
      </StyledTableCell>

      <StyledTableCell align={'right'}>
        <IconButton
          disabled={progress < 100}
          sx={{ marginLeft: 'auto', textTransform: 'none', color: COLOR_BLACK }}
          onClick={handleRemoveFile}
        >
          <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px !important', fill: GRAY_COLORS.GRAY_5 }} />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: inherit !important;
  padding-top: 14px;
  padding-bottom: 12px;
`;

export default FileNotSupportedCard;
