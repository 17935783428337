import { IconButton, Input } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

import SendIconWhite from '../../assets/icons/send-message-white.svg';
import SendIconGray from '../../assets/icons/send-message-gray.svg';
import { GRAY_COLORS } from '../../constants/colors';

import { ConversationTypes } from '../../constants/conversationTypes';
import { fileStatus } from '../../constants/fileStatus';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { Paths } from '../../constants/routes';
import { useStore } from '../../hooks/useStore';
import { EMPTY_CHAT } from '../../models/Chat';
import { Conversation, CreateConversationDto, INITIAL_CONVERSATION } from '../../models/Conversation';
import { getDocumentsKey } from '../../utils/documentsS3';
import { uploadDriveFile } from '../../utils/uploadFile';
import PrimaryIconButton from '../buttons/PrimaryIconButton';
import UploadDocument from '../fileUpload/UploadDocument';
import Flex from '../utils/flex/Flex';
import Box from '@material-ui/core/Box/Box';
import { toJS } from 'mobx';

interface MessageInputProps {
  textAreaPlaceholder?: string;
}

const MessageInput = ({ textAreaPlaceholder }: MessageInputProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      createConversation,
      addChat,
      setInitialConversation,
      currentConversation,
      messageInput,
      setMessageInput,
      shouldInputBeFocused,
      setShouldInputBeFocused,
      conversationSettingsState,
      isSecondaryModelSelected,
      setCompletionMessage,
      redactPiiData,
      setMultipleModelsRedactedChatMessage,
      addFileToConversation,
      setIsUploadInProgress,
      cancelFileUpload,
      setUploadingConversationId,
      setUploadingFileId,
      setUploadProgress,
      dataAnalysisEnablingPending,
      setDataAnalysisEnablingPending,
      addDataAnalysisBotChat,
    },
    fileStore: { createFile },
    userStore: { userData },
    uiStore: {
      isStartNewConversationFromPromptOpen,
      toggleOpenAiSettingsSection,
      toggleOpenAiCompareAnswersModalOpen,
      toggleMessageWasBlockedModalOpen,
      toggleIsPromptsDrawerExpanded,
    },
    modelStore: { organizationModels },
    appState: { googleDriveApi, fileApi },
  } = useStore();
  const gDriveToken = localStorage.getItem(LocalStorageKeys.googleDriveAccessToken);

  const { chats, files, id: conversationId, isStartedFromKnowledgeBase } = currentConversation;

  //look at all active models and search for a file one
  const noFileModelEnabled = organizationModels
    .filter(model => model.isActive)
    .every(model => !model.modelVersion.includes('file'));

  const hasUserStartedAskingQuestions =
    chats?.filter(chat => {
      const isFileChat = Array.isArray(chat?.files) && chat?.files?.length > 0 && chat?.files[0].id.length > 0;

      if (isFileChat) {
        // return false as this is not a chat with a message
        return false;
      }

      // if only the data analysis chat exists, we allow the user to upload files
      return chat.message !== i18n.t('conversation.dataAnalysis.notEnabled');
    }).length > 0;

  const [doesChatBelongToCurrentUser, setDoesChatBelongToCurrentUser] = useState(false);

  const { addToast } = useToasts();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const { model, accuracy, length, providerName } = conversationSettingsState;
  const isFileModel = model.includes('file');

  let id: null | string = currentConversation?.user?.id;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingChatConversationId, setLoadingChatConversationId] = useState('');

  useEffect(() => {
    setDoesChatBelongToCurrentUser(id ? id === userData.id : true);
  }, [id, userData]);

  const focusOnInput = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  };

  useEffect(focusOnInput, []);

  useEffect(() => {
    if (shouldInputBeFocused) {
      inputRef.current?.focus();
      setShouldInputBeFocused(false);
    }
  }, [shouldInputBeFocused]);

  const handleCreateConversation = async () => {
    if (!messageInput.trim().length) {
      return;
    }

    if (isSecondaryModelSelected) {
      const result = await redactPiiData(messageInput);

      if (result.wasBlocked) {
        setMultipleModelsRedactedChatMessage({ ...EMPTY_CHAT, ...result });
        toggleMessageWasBlockedModalOpen(true);
        return;
      }

      toggleOpenAiCompareAnswersModalOpen(true);
      setCompletionMessage(messageInput);

      return;
    }

    try {
      toggleOpenAiSettingsSection(false);
      setLoading(true);
      setInitialConversation(messageInput, model, providerName, accuracy, length, userData);
      let conversation: Conversation = INITIAL_CONVERSATION;
      if (messageInput.includes('https://docs.google.com/')) {
        await addGoogleDriveLinkMessage(messageInput);
      } else {
        const conversationDto: CreateConversationDto = {
          type: ConversationTypes.Chat,
          model: model,
          temperature: accuracy,
          maxTokens: length,
          providerName,
          chat: {
            message: messageInput,
          },
        };
        conversation = (await createConversation(conversationDto)) || INITIAL_CONVERSATION;
      }

      if (conversation?.id) {
        navigate(`${Paths.CHAT}/${conversation.id}`, { state: {} });
      }
    } catch (e) {
      console.log('e ------------------->> ', e);
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    } finally {
      setLoading(false);
      focusOnInput();
    }
  };

  const addGoogleDriveLinkMessage = async (fileUrl: string) => {
    const fileName = 'driveFile.pdf';
    const fileKey = getDocumentsKey(userData.id, fileName);
    const createdFileEntry = await createFile({ key: fileKey, name: `${fileUrl}.pdf` });
    if (gDriveToken) {
      try {
        const tokenInfo = await googleDriveApi.getTokenInfo(gDriveToken);
        if (tokenInfo) {
          const conversation = await assignFilesToConversation([
            { id: createdFileEntry.id, name: createdFileEntry.name },
          ]);
          const regex = /[-\w]{25,}/;
          const fileData = fileUrl?.match(regex);
          const fileId = fileData?.length ? fileData[0] : '';

          setMessageInput('');
          setIsUploadInProgress(true);
          setUploadingConversationId(conversation.id);
          setUploadingFileId(createdFileEntry.id);
          setUploadProgress(0);
          const objToUpload = {
            documentIds: [{ id: fileId }],
            accessToken: gDriveToken,
            fileKeys: [fileKey],
          };
          await uploadDriveFile({
            googleDriveObject: objToUpload,
            conversationId: conversation.id,
            createdFiles: [createdFileEntry.id],
            googleDriveApi,
            fileApi,
            setIsUploadInProgress,
            cancelFileUpload,
            addToast,
            i18n,
          });
        }
      } catch (e) {
        const url = await googleDriveApi.getConnectionUrl(`${location.origin}/chat`);
        window.open(url, '_self');
      }
    } else {
      const url = await googleDriveApi.getConnectionUrl(`${location.origin}/chat`);
      window.open(url, '_self');
    }
  };

  const handleAddChat = async () => {
    if (loading) {
      return;
    }
    try {
      setLoadingChatConversationId(conversationId);
      if (messageInput.includes('https://docs.google.com/')) {
        await addGoogleDriveLinkMessage(messageInput);
      } else {
        // if the user did not explicitly click yes or no, we automatically not enable it
        if (dataAnalysisEnablingPending) {
          await addDataAnalysisBotChat(i18n.t('conversation.dataAnalysis.notEnabled'));
          setDataAnalysisEnablingPending(false);
        }
        await addChat(messageInput);
      }
    } catch (e) {
      console.log('e ------------------->> ', e);
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    } finally {
      setLoadingChatConversationId('');
      focusOnInput();
    }
  };

  const assignFilesToConversation = async (
    files: {
      id: string;
      name: string;
    }[]
  ) => {
    let conversation: Conversation;
    if (currentConversation && !currentConversation.id) {
      conversation =
        (await createConversation({
          type: ConversationTypes.Chat,
          model: model,
          temperature: accuracy,
          maxTokens: length,
          providerName,
          chat: {
            files: files,
          },
        })) || INITIAL_CONVERSATION;
    } else {
      conversation = await addFileToConversation(currentConversation.id, files);
    }

    return conversation;
  };

  const inputDisabled =
    !doesChatBelongToCurrentUser ||
    loading ||
    (loadingChatConversationId && loadingChatConversationId === conversationId) ||
    (Array.isArray(files) &&
      files.length > 0 &&
      files.some(file => file.status !== fileStatus.DONE) &&
      !isStartedFromKnowledgeBase) ||
    (isFileModel && !conversationId);

  return (
    <StyledInputContainer>
      <StyledInputElement
        sx={{ padding: '8px' }}
        disableUnderline
        multiline
        ref={inputRef}
        placeholder={
          isFileModel && !conversationId
            ? i18n.t('conversation.input.uploadFilePlaceholder')
            : doesChatBelongToCurrentUser
            ? textAreaPlaceholder
            : 'This is not your conversation. You cannot type messages here. Feel free to start a new one.'
        }
        value={messageInput}
        onChange={event => setMessageInput(event.target.value)}
        disabled={inputDisabled}
        onKeyDown={({ key, shiftKey }) => {
          // if shift and enter are pressed, default behaviour should happen(new line in text box)
          if (key === 'Enter' && shiftKey) {
            return;
          }

          if (key === 'Enter') {
            toggleIsPromptsDrawerExpanded(false);
            id ? handleAddChat() : handleCreateConversation();
          }
        }}
      />
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Flex>
          {/*<StyledIconButton>*/}
          {/*  <AddOutlinedIcon sx={{ width: '20px', height: '20px', fill: GRAY_COLORS.GRAY_6 }} />*/}
          {/*</StyledIconButton>*/}

          {model.includes('file') && !hasUserStartedAskingQuestions && (
            <UploadDocument
              disabled={noFileModelEnabled}
              disabledMessage={i18n.t('conversation.noFileModel')}
              assignFilesToConversation={assignFilesToConversation}
            />
          )}

          <StyledIconButton onClick={() => toggleIsPromptsDrawerExpanded()}>
            <LightbulbOutlinedIcon sx={{ width: '20px', height: '20px', fill: GRAY_COLORS.GRAY_6 }} />
          </StyledIconButton>
        </Flex>

        {inputDisabled || !messageInput.trim().length ? (
          <IconButton disabled>
            <img src={SendIconGray} alt={'send-icon-disabled'} />
          </IconButton>
        ) : (
          <PrimaryIconButton
            onClick={() => {
              toggleIsPromptsDrawerExpanded(false);
              id ? handleAddChat() : handleCreateConversation();
            }}
          >
            <img src={SendIconWhite} alt={'send-icon'} />
          </PrimaryIconButton>
        )}
      </Flex>
    </StyledInputContainer>
  );
};

const StyledInputElement = styled(Input)`
  width: 100%;
  max-height: 100px !important;

  & .MuiInputBase-input {
    max-height: 100px !important;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 6px;
`;

const StyledInputContainer = styled(Box)`
  padding: 8px;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${GRAY_COLORS.GRAY_2};
`;

export default observer(MessageInput);
